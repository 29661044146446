import { Container } from "@mui/material"
import { UserType } from "contexts/AuthContext"
import { useEffect, useState } from "react"
import { z } from "zod"
import LoginComponent, { loginZod } from "../components/auth/Login"
import React from "react"
import { getPosts } from "components/CMSGlobalPage"
import Head from "next/head"
import router from "next/router"
import { useTranslation } from "react-i18next"
import htmlEncode from "utils/htmlEncode"
import generateConfig from "configs/config"
const config = generateConfig()
const getSignUpContent = {
  query: `query NewQuery {
    nodeByUri(uri: "/signup-content") {
      ... on Page {
        id
        blocksJSON
      }
    }
  }`,
  variables: {
    variables: {},
  },
}

const Login = (props: any) => {
  const [loading, setLoading] = React.useState(false)
  const [continueUrl, setContinueUrl] = useState("")

  const onUrlChange = (continueUrl: string) => {
    setContinueUrl(continueUrl)
  }

  const mockLogin = (data: z.infer<typeof loginZod>) => {
    setLoading(true)
  }

  useEffect(() => {
    if (localStorage.hasOwnProperty("step")) {
      localStorage.removeItem("step")
    }
    const htmlElement = document.querySelector("html")
    if (htmlElement && htmlElement.hasAttribute("style")) {
      htmlElement.removeAttribute("style")
    }
  }, [])

  useEffect(() => {
    router.push(continueUrl)
  }, [continueUrl])

  const { t } = useTranslation()
  return (
    <>
      <Head>
        <title>{`${t("navBarMenu.signIn")} | ${config?.metaSiteName}`}</title>
        <meta
          name="description"
          content={htmlEncode(
            config?.metaSiteName === "Save Mart"
              ? `${t("metadata.loginDescriptionSavemart")}`
              : config?.metaSiteName === "Lucky Supermarkets"
              ? `${t("metadata.loginDescriptionLucky")}`
              : `${t("metadata.loginDescriptionFoodMaxx")}`
          )}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0; user-scalable=1"
        />
      </Head>
      <Container maxWidth="lg">
        <LoginComponent
          onSubmit={mockLogin}
          loading={loading}
          onUrlChange={onUrlChange}
          props={props}
        />
      </Container>
    </>
  )
}

export async function getStaticProps() {
  const props = await getPosts(getSignUpContent)
  return { props, revalidate: 10800 }
}

Login.redirectAuthenticatedTo = "/"

export default Login
