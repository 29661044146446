import { CloseIcon } from "@/icons"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Box from "@mui/material/Box"
import axios from "axios"
import { useRouter } from "next/navigation"
import React, { FormEventHandler, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { z, ZodError } from "zod"
import { TextInput } from "../common/textInput/TextInput"
import ForgotPassword from "./ForgotPassword"
import ChangePassword from "./ChangePassword"
import PasswordSuccess from "./PasswordSuccess"
import { styles } from "./Register"
import styless from "../../styles/component.module.scss"
import PageRender from "components/pageRender"
import { useAuth } from "contexts/AuthContext"
import generateConfig from "../../configs/config"
import { gaLogEvent, EventName } from "utils/googleAnalyticsEvents"
import router from "next/router"
import { openSnackbar } from "contexts/actions/actions"
import { useAppContext } from "contexts/AppContext"
import { faroPushErrors } from "utils/faroGrafanaLogs"

const configEnv = generateConfig()

export const loginZod = z.object({
  email: z.string().email({ message: "Email is required" }),
  password: z
    .string()
    .min(6, { message: "Password should be at least 6 character" })
    .max(12, { message: "Password can not be longer than 12 character" }),
  rememberMe: z.boolean(),
})

interface LoginFormType {
  email: {
    value: string
    error?: string
  }
  password: {
    value: string
    error?: string
  }
  rememberMe: {
    value: boolean
    error: string
  }
}

export interface LoginProps {
  onSubmit: (data: z.infer<typeof loginZod>) => void
  loading: any
  onUrlChange: any
  props: any
}

const Login = ({ props, onUrlChange }: LoginProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [loginError, setLoginError] = React.useState("")
  const [loader, setLoader] = React.useState<boolean>(false)
  const { updateRegisterInfo, setInitialState, setSwiftlyAccessToken } =
    useAuth()
  const [forgotPassError, setForgotPassError] = useState<string>("")
  const [changePassError, setChangePassError] = useState<string>("")
  const [profileFormData, setProfileFormData] = React.useState<LoginFormType>({
    email: {
      value:
        (typeof window !== "undefined" && localStorage.getItem("rememberme")) ||
        "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    rememberMe: {
      value: false,
      error: "",
    },
  })
  const [rememberMeChecked, setRememberMeChecked] = React.useState<boolean>(
    typeof window !== "undefined" ? !!localStorage.getItem("rememberme") : false
  )
  const { t } = useTranslation()
  const { push } = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280))
  const isTablet = !isMobile && !isDesktop
  const [isloading, setLoading] = React.useState(false)
  //forgot password modal
  const [open, setOpen] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openPassworSuccess, setPasswordSuccess] = useState(false)
  const [passwordRecoveryUrl, setPasswordRecoveryUrl] = useState("")
  const [email, setEmail] = useState("")
  const { nodeByUri } = props.data
  const { blocksJSON } = nodeByUri
  const parsedBlock = JSON.parse(blocksJSON)
  const { dispatch } = useAppContext()

  const onSaveProfile: FormEventHandler = (e) => {
    setLoginError("")
    e.preventDefault()
    loginZod
      .parseAsync({
        email: profileFormData.email.value,
        password: profileFormData.password.value,
        rememberMe: rememberMeChecked,
      })
      .then(async (a) => {
        setLoading(true)
        setInitialState((prevState: any) => ({
          ...prevState,
          actionInprogress: true,
        }))
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof LoginFormType] = {
              ...prev[key as keyof LoginFormType],
              error: undefined,
            }
            return acc
          }, {} as LoginFormType)
        })
        for (const aKey in a) {
          if (a[aKey as keyof typeof a] === undefined)
            delete a[aKey as keyof typeof a]
        }
        try {
          if (configEnv.pingCloud === "true") {
            await axios
              .post(
                "/pingcloud",
                {
                  grant_type: "password",
                  username: profileFormData.email.value,
                  password: profileFormData.password.value,
                },
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then(async (result: any) => {
                if (result.status === 200) {
                  setSwiftlyAccessToken(result.data)
                  router.push({ pathname: "/" })
                }
              })
              .catch((err: any) => {
                faroPushErrors(
                  err?.response?.data
                    ? err?.response?.data
                    : "Failed to connect"
                )
                setLoading(false)
                setInitialState((prevState: any) => ({
                  ...prevState,
                  actionInprogress: false,
                }))
                // setLoginError(err.message)
                const body = err?.response?.data
                  ? err?.response?.data
                  : "Failed to connect"

                dispatch(
                  openSnackbar({
                    variant: "alert",
                    message: err?.response?.data?.message
                      ? err?.response?.data?.message
                      : t("common.somethingWentWrong"),
                    alert: {
                      color: "error",
                    },
                  })
                )
                return {
                  status: err?.response.status ? err?.response.status : 500,
                  body,
                }
              })
          } else {
            await axios
              .get("/api/login", { withCredentials: true })
              .then(async (result: any) => {
                await getRedirectUrl(result.data.LoginUrl.split("flow=")[1])
              })
          }
        } catch (err: any) {
          faroPushErrors(
            err?.response?.data ? err?.response?.data : "Failed to connect"
          )
          setLoading(false)
          setInitialState((prevState: any) => ({
            ...prevState,
            actionInprogress: false,
          }))
          setLoginError(err.message)
          const body = err?.response?.data
            ? err?.response?.data
            : "Failed to connect"
          return { status: body.status ? body.status : 500, body }
        }
      })
      .catch((err: ZodError) => {
        faroPushErrors(err ? err : t("common.somethingWentWrong"))

        setLoading(false)
        setInitialState((prevState: any) => ({
          ...prevState,
          actionInprogress: false,
        }))
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof LoginFormType] = {
              ...prev[key as keyof LoginFormType],
              error: err.issues.find((issue) => issue.path[0] === key)?.message,
            }
            return acc
          }, {} as LoginFormType)
        })
      })
  }
  //ItWillReturnContinueUrl
  const getRedirectUrl = async (url: string) => {
    try {
      await axios
        .get(url, { withCredentials: true })
        .then(async (result: any) => {
          await loginPutAPI(result.data.meta.location)
        })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      setLoading(false)
      setInitialState((prevState: any) => ({
        ...prevState,
        actionInprogress: false,
      }))
      setLoginError(err.message)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }
  const loginPutAPI = async (url: string) => {
    const request = {
      "urn:pingidentity:scim:api:messages:2.0:UsernamePasswordAuthenticationRequest":
        {
          password: profileFormData.password.value,
          username: profileFormData.email.value,
        },
    }
    try {
      await axios
        .put(url, request, { withCredentials: true })
        .then(async (result: any) => {
          if (result.data.success === false) {
            setLoading(false)
            setInitialState((prevState: any) => ({
              ...prevState,
              actionInprogress: false,
            }))
            setLoginError(
              result.data[
                "urn:pingidentity:scim:api:messages:2.0:UsernamePasswordAuthenticationRequest"
              ].error
            )
          } else {
            await loginGetRedirectionUrl(result.data.followUp.$ref)
          }
        })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      setLoading(false)
      setInitialState((prevState: any) => ({
        ...prevState,
        actionInprogress: false,
      }))
      setLoginError(err.message)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }
  //ItWillReturnContinueUrl
  const loginGetRedirectionUrl = async (url: string) => {
    try {
      await axios.get(url, { withCredentials: true }).then((result: any) => {
        onUrlChange(result.data.continue_redirect_uri)
      })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      setLoading(false)
      setInitialState((prevState: any) => ({
        ...prevState,
        actionInprogress: false,
      }))
      setLoginError(err.message)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  const handleClickOpen = () => {
    setForgotPassError("")
    setOpenChangePassword(false)
    setPasswordSuccess(false)
    setLoginError("")
    setOpen(true)
  }

  const handleChangePassword = () => {
    setPasswordSuccess(false)
    setOpen(false)
    setOpenChangePassword(true)
  }

  const handlePasswordSuccess = () => {
    setOpen(false)
    setOpenChangePassword(false)
    setPasswordSuccess(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenChangePassword(false)
  }

  const handlePassSuccessClose = () => {
    setPasswordSuccess(false)
  }

  const getCode = () => {
    getLoginUrlForgotPass(email)
  }

  const getLoginUrlForgotPass = async (email: string) => {
    setForgotPassError("")
    setLoader(true)
    if (configEnv.pingCloud !== "true") {
      try {
        await axios.get("/api/login").then(async (result: any) => {
          await getPasswordUrl(result.data.LoginUrl.split("flow=")[1], email)
        })
      } catch (err: any) {
        setLoader(false)
        setForgotPassError(err.message)
        const body = err?.response?.data
          ? err?.response?.data
          : "Failed to connect"
        return { status: body.status ? body.status : 500, body }
      }
      setLoader(false)
    } else {
      try {
        await axios
          .post(`api/forgotPassword`, { email })
          .then(async (result: any) => {
            setEmail(email)
            setPasswordRecoveryUrl(result.data.changePasswordUrl)
            handleChangePassword()
          })
      } catch (err: any) {
        faroPushErrors(
          err?.response?.data ? err?.response?.data : "Failed to connect"
        )
        setLoader(false)
        const body = err?.response?.data
          ? err?.response?.data
          : "Failed to connect"
        return { status: body.status ? body.status : 500, body }
      }
      setLoader(false)
    }
  }

  const getPasswordUrl = async (url: string, email: string) => {
    try {
      await axios.get(url).then(async (result: any) => {
        await putForgotPassEmail(
          email,
          result.data[
            "urn:pingidentity:scim:api:messages:2.0:UsernamePasswordAuthenticationRequest"
          ].passwordRecovery.$ref
        )
      })
    } catch (err: any) {
      setLoader(false)
      setForgotPassError(err.message)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  const putForgotPassEmail = async (email: string, url: string) => {
    setEmail(email)
    const request = {
      "urn:pingidentity:scim:api:messages:2.0:AccountLookupRequest": {
        identifier: email,
      },
      "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest":
        {
          codeRequested: true,
        },
    }
    try {
      await axios.put(url, request).then(async (result: any) => {
        if (result.data.success === true) {
          setLoader(false)
          setForgotPassError(
            result.data[
              "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest"
            ].error
          )
        } else {
          if (
            result.data[
              "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest"
            ].codeSent == true
          ) {
            setPasswordRecoveryUrl(result.data.meta.location)
            setLoader(false)
            handleChangePassword()
          } else {
            setLoader(false)
            if (
              result.data[
                "urn:pingidentity:scim:api:messages:2.0:AccountLookupRequest"
              ].error == "noMatch"
            )
              setForgotPassError(t("forgotPassword.errorMessage"))
          }
        }
      })
    } catch (err: any) {
      setLoader(false)
      setForgotPassError(err.message)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  const putForgotPassCode = async (data: any) => {
    setLoader(true)
    const request = {
      newPassword: data.newPassword,
      "urn:pingidentity:scim:api:messages:2.0:AccountLookupRequest": {
        identifier: email,
      },
      "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest":
        {
          verifyCode: data.verifyCode,
        },
    }
    try {
      await axios
        .put(passwordRecoveryUrl, request)
        .then(async (result: any) => {
          if (result.data.success === true) {
            setOpenChangePassword(false)
            setLoader(false)
            handlePasswordSuccess()
            setChangePassError("")
          } else {
            setLoader(false)
            if (
              result.data[
                "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest"
              ].codeSent == true
            ) {
              setChangePassError(
                result.data[
                  "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest"
                ].error
              )
            } else {
              setChangePassError(
                result.data[
                  "urn:pingidentity:scim:api:messages:2.0:EmailDeliveredCodeAuthenticationRequest"
                ].error
              )
            }
          }
        })
    } catch (err: any) {
      setLoader(false)
      setChangePassError(err.response.data.detail)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  const putForgotPassCodeFlow = async (payload: {
    newPassword: string
    verifyCode: string
  }) => {
    const request = {
      code: payload.verifyCode,
      email: email,
      password: payload.newPassword,
      opaqueMetadata: {
        changePasswordUrl: passwordRecoveryUrl,
      },
    }
    setForgotPassError("")
    setLoader(true)
    try {
      await axios
        .post(`api/submitForgotPasswordCode`, request)
        .then(async (result: any) => {
          handlePasswordSuccess()
        })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data?.message?.data
          ? err?.response?.data?.message?.data
          : "Failed to connect"
      )
      setLoader(false)
      setChangePassError(err.response.data.message.data)
      const body = err?.response?.data?.message?.data
        ? err?.response?.data?.message?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
    setLoader(false)
  }

  const handleForgotPassword = (e: any) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      const target = e.target as HTMLButtonElement
      handleClickOpen()
      gaLogEvent({
        eventName: EventName.password_forgotPassword,
        parameters: {
          text_click: target.textContent,
          item_location: window.location.pathname,
        },
      })
    }
  }

  useEffect(() => {
    if (rememberMeChecked && typeof window !== "undefined") {
      localStorage.setItem("rememberme", profileFormData?.email?.value)
    }
  }, [profileFormData, rememberMeChecked])

  const forgotApiSelection = async (data: any) => {
    if (configEnv.pingCloud === "true") await putForgotPassCodeFlow(data)
    else await putForgotPassCode(data)
  }

  return (
    <Box
      mt={3}
      sx={{ display: "flex", justifyContent: "center" }}
      className={styless.formSect}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {configEnv.configBanner !== "foodmaxx" ? (
          <Typography
            variant="h1"
            fontSize={isMobile ? "20px" : "24px"}
            sx={styles.pageTitle}
          >
            {t("login.pageTitle")}
          </Typography>
        ) : (
          <Typography
            variant="h1"
            fontSize={isMobile ? "20px" : "24px"}
            sx={styles.pageTitle}
          >
            {t("login.foodmaxxTitle")}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexDirection: isMobile || isTablet ? "column" : "Row",
          }}
        >
          <Box
            width={isMobile ? "100%" : 580}
            marginTop={3}
            sx={{
              backgroundColor: `${theme.palette.bgPrimary.main}`,
              height: "fit-content",
              ...(isMobile
                ? { ...styles.card, ...styles.cardMobile }
                : { ...styles.card }),
            }}
          >
            <form onSubmit={onSaveProfile}>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <Typography variant="h2" sx={styles.title} mb={2}>
                  {t("login.title")}
                </Typography>
                <TextInput
                  aria-required="true"
                  label={t("login.email")}
                  placeholder={t("login.email")}
                  autoComplete={t("login.email")}
                  value={profileFormData.email.value}
                  sx={{
                    ...styles.textInput,
                    "&.MuiFormControl-root": { mb: 2, label: { top: -16 } },
                  }}
                  labelSx={styles.inputLabel}
                  onChange={(e) => {
                    setProfileFormData((prev) => ({
                      ...prev,
                      email: {
                        ...prev.email,
                        value: e.target.value,
                        error: "",
                      },
                    }))
                    setLoginError("")
                  }}
                  error={!!profileFormData.email.error}
                  helperText={profileFormData.email.error}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <TextInput
                  aria-required="true"
                  label={t("login.password")}
                  autoComplete={t("login.password")}
                  placeholder={t("login.password")}
                  value={profileFormData.password.value}
                  sx={{
                    ...styles.textInput,
                    "&.MuiFormControl-root": { mb: 2, label: { top: -16 } },
                  }}
                  className="mb-0"
                  labelSx={styles.inputLabel}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={styles.showBtn}
                          aria-label={
                            showPassword
                              ? t("ariaLabels.hide")
                              : t("ariaLabels.show")
                          }
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setProfileFormData((prev) => ({
                      ...prev,
                      password: {
                        ...prev.password,
                        value: e.target.value,
                        error: "",
                      },
                    }))
                    setLoginError("")
                  }}
                  error={!!profileFormData.password.error}
                  helperText={profileFormData.password.error}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <div>
                  <FormControlLabel
                    sx={{ ...styles.formlabel, marginTop: 0 }}
                    control={
                      <Checkbox
                        tabIndex={0}
                        checked={rememberMeChecked}
                        onChange={(e) => {
                          if (
                            e.target.checked &&
                            typeof window !== "undefined"
                          ) {
                            localStorage.setItem(
                              "rememberme",
                              profileFormData.email.value
                            )
                          } else {
                            if (typeof window !== "undefined") {
                              localStorage.setItem("rememberme", "")
                            }
                          }
                          setRememberMeChecked(!rememberMeChecked)
                        }}
                        name="rememberMe"
                        color="ctaPrimary"
                      />
                    }
                    label={t("login.rememberMe")}
                  />
                </div>
                {loginError && (
                  <Typography color={theme.palette.ctaSecondary?.main} mt={2}>
                    {loginError}
                  </Typography>
                )}
                <LoadingButton
                  variant="contained"
                  color="ctaPrimary"
                  sx={styles.button}
                  loading={isloading}
                  type="submit"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    const target = e.target as HTMLButtonElement
                    gaLogEvent({
                      eventName: EventName.login_click,
                      parameters: {
                        text_click: target.textContent,
                        item_location: window.location.pathname,
                      },
                    })
                  }}
                >
                  {t("login.login")}
                </LoadingButton>
                <Box sx={styles.links}>
                  <Link
                    color="ctaPrimary.main"
                    role="button"
                    tabIndex={0}
                    sx={{ cursor: "pointer" }}
                    aria-label="Forgot password"
                    data-cy="forgot-password-link"
                    onKeyDown={handleForgotPassword}
                    onClick={handleForgotPassword}
                  >
                    {t("login.forgotPassword")}
                  </Link>
                </Box>
              </FormControl>
            </form>
            {/* ForgotPassModal */}
            <Dialog
              onClose={handleClose}
              aria-label={t("ariaLabels.forgotPasswordModal")}
              sx={styles.dialogContent}
              open={open}
              disableRestoreFocus
            >
              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4" sx={styles.title}>
                    {t("forgotPassword.title")}
                  </Typography>

                  <IconButton
                    sx={{
                      padding: 0,
                      ":hover": { backgroundColor: "unset" },
                    }}
                    onClick={handleClose}
                    aria-label={t("ariaLabels.closeButton")}
                  >
                    <CloseIcon color={theme.palette.ctaPrimary.main} />
                  </IconButton>
                </Stack>
              </Stack>
              <DialogContent
                sx={{
                  marginTop: 2,
                }}
              >
                <ForgotPassword
                  linkSent={false}
                  loading={loader}
                  loginError={forgotPassError}
                  setForgotPassError={setForgotPassError}
                  onSubmit={async function (data: {
                    email: string
                  }): Promise<void> {
                    await getLoginUrlForgotPass(data.email)
                  }}
                />
              </DialogContent>
            </Dialog>

            {/*ChangePasswordComponent*/}
            <Dialog
              onClose={handleClose}
              aria-label={t("ariaLabels.changePasswordModal")}
              open={openChangePassword}
              sx={styles.dialogContentChange}
              disableRestoreFocus
            >
              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4" sx={styles.title}>
                    {t("forgotPassword.setNewPassword")}
                  </Typography>
                  <IconButton
                    sx={{
                      padding: 0,
                      ":hover": { backgroundColor: "unset" },
                    }}
                    onClick={handleClose}
                    aria-label={t("ariaLabels.closeButton")}
                  >
                    <CloseIcon color={theme.palette.ctaPrimary.main} />
                  </IconButton>
                </Stack>
              </Stack>
              <DialogContent sx={{}}>
                <ChangePassword
                  getCode={getCode}
                  email={email}
                  isSetPasswordPage={false}
                  changePassError={changePassError}
                  setChangePassError={setChangePassError}
                  loading={loader}
                  onSubmit={async function (data: {
                    newPassword: string
                    verifyCode: string
                  }): Promise<void> {
                    await forgotApiSelection(data)
                  }}
                />
              </DialogContent>
            </Dialog>

            {/* PasswordSuccessModal */}
            <Dialog
              onClose={handlePassSuccessClose}
              aria-label={t("ariaLabels.passwordSuccessModal")}
              open={openPassworSuccess}
              sx={styles.dialogContent}
            >
              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4" sx={styles.title}>
                    {t("forgotPassword.forgotPass")}
                  </Typography>
                </Stack>
              </Stack>
              <DialogContent
                sx={{
                  marginTop: 1,
                }}
              >
                <PasswordSuccess
                  linkSent={false}
                  onSubmit={async function (): Promise<void> {
                    handlePassSuccessClose()
                  }}
                />
              </DialogContent>
            </Dialog>
          </Box>

          <Box
            width={isMobile ? "100%" : 580}
            marginTop={isMobile || isTablet ? 0 : 3}
            sx={{
              backgroundColor: `${theme.palette.bgPrimary.main}`,
              ...(isMobile
                ? { ...styles.card, ...styles.cardMobile }
                : { ...styles.card }),
            }}
            className={styless.rightBox}
          >
            {parsedBlock?.map((blocks: any, index: number) => (
              <React.Fragment key={index}>
                <PageRender node={blocks} />
              </React.Fragment>
            ))}

            <Button
              data-cy="sign-up-user"
              aria-label={t("login.signUp")}
              variant="outlined"
              color="ctaSuccess"
              sx={{ ...styles.outlinedButton, marginTop: 2 }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                const target = e.target as HTMLButtonElement
                updateRegisterInfo("")
                push("/register")
                gaLogEvent({
                  eventName: EventName.signup_click,
                  parameters: {
                    text_click: target.textContent,
                    item_location: window.location.pathname,
                  },
                })
              }}
            >
              {t("login.signUp")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Login
