import { Button, Typography, Box, useTheme, Stack } from "@mui/material"
import styless from "../styles/component.module.scss"
import Image from "next/image"
import React from "react"
import { useTranslation } from "react-i18next"
export const styles = {
  heading: {
    marginTop: 3,
    marginBottom: 3,
    fontWeight: 600,
  },
  content: {
    lineHeight: "24px",
    marginBottom: 1,
  },
  button: {
    marginTop: 2,
    marginRight: 2,
    padding: 2,
    color: "bgPrimary.main",
    fontSize: 15,
    lineHeight: "16px",
    backgroundColor: "ctaPrimary.main",
    "&:hover": {
      backgroundColor: "ctaPrimary.main",
      color: "bgPrimary.main",
    },
  },
}
const PageRender = (props: any) => {
  const { node } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const buildField = (field: any) => {
    const fieldType = field.blockType.name
    // console.log(field)
    switch (fieldType) {
      case "core/paragraph":
        return renderText(field)
        break
      case "core/image":
        return renderImage(field)
        break
      case "core/list":
        return renderList(field.innerBlocks)
        break
      case "core/media-text":
        return renderMediaText(field)
        break
      case "core/heading":
        return renderHeading(field)
        break
      case "core/table-of-contents":
        return renderTableContent(field)
        break
      case "core/table":
        return renderTable(field)
        break
      case "contact-form-7/contact-form-selector":
        return renderTable(field)
        break
      case "core/code":
        return renderCode(field)
        break
      case "core/columns":
        return renderListItem(field)
        break
      case "core/separator":
        return renderTable(field)
        break
      case "core/buttons":
        return renderButtonsListItem(field)
        break
      case "core/group":
        return renderGroupItem(field)
        break
      default:
        break
    }
  }

  const renderGroupItem = (fields: any) => {
    return (
      <Stack direction="row">
        {fields.innerBlocks.length > 0 &&
          fields.innerBlocks.map((blocks: any, index: number) => (
            <React.Fragment key={index}>{buildField(blocks)}</React.Fragment>
          ))}
      </Stack>
    )
  }

  const renderTable = (fields: any) => {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: fields.originalContent }}></div>
      </>
    )
  }

  const renderButtonsListItem = (fields: any) => {
    return (
      <>
        <div>
          {fields.innerBlocks.length > 0 &&
            fields.innerBlocks.map((blocks: any, index: number) => (
              <React.Fragment key={index}>
                <Button
                  key={index}
                  color="secondary"
                  sx={styles.button}
                  onClick={() => open(blocks.attributes.rel, "_blank")}
                  aria-label={`${t("ariaLabels.open")} ${
                    blocks?.attributes?.text || ""
                  } ${t("ariaLabels.inNewTab")}`}
                >
                  {blocks?.attributes?.text}
                </Button>
              </React.Fragment>
            ))}{" "}
        </div>
      </>
    )
  }
  const renderListItem = (fields: any) => {
    return (
      <>
        {fields.innerBlocks.length > 0 &&
          fields.innerBlocks.map((blocks: any, index: number) => (
            <React.Fragment key={index}>
              <span
                className={styless.loginHeading}
                dangerouslySetInnerHTML={{
                  __html: blocks.saveContent,
                }}
              ></span>
            </React.Fragment>
          ))}{" "}
      </>
    )
  }
  const renderCode = (fields: any) => {
    return <>{fields.attributes.content}</>
  }

  const renderTableContent = (fields: any) => {
    return <>{fields.attributes.content}</>
  }
  const renderHeading = (fields: any) => {
    return (
      <>
        <Typography
          id={fields.attributes?.anchor || "header"}
          dangerouslySetInnerHTML={{ __html: fields.originalContent }}
        ></Typography>
        {/* <div> <h3>{fields.attributes.content}</h3></div> */}
      </>
    )
  }

  const renderText = (fields: any) => {
    return (
      <>
        <Typography
          variant="paragraph"
          color="textPrimary.main"
          sx={styles.content}
          className={styless.policyContent}
          dangerouslySetInnerHTML={{ __html: fields.attributes.content }}
        ></Typography>
      </>
    )
  }

  const renderList = (fields: any) => {
    return (
      <>
        {fields &&
          fields.map((blocks: any, index: number) => (
            <React.Fragment key={index}>
              <Box
                className={styless.listArticle}
                sx={{ a: { color: `${theme.palette.ctaPrimary.main}` } }}
              >
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: blocks.attributes.content,
                    }}
                  ></li>
                </ul>
              </Box>
            </React.Fragment>
          ))}{" "}
      </>
    )
  }

  const renderImage = (fields: any) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: fields.originalContent }}></div>
    )
  }

  const renderMediaText = (fields: any) => {
    return (
      <>
        <Image src={fields.attributes.mediaUrl} alt="Media-Url" />
      </>
    )
  }

  return (
    <>
      <div>{buildField(node)}</div>
    </>
  )
}
export default PageRender
